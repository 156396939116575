import React from 'react'	// eslint-disable-line no-unused-vars
import ReactDom from 'react-dom'

import { MuiThemeProvider } from '@material-ui/core/styles'
import { BrowserRouter, Route, Switch } from 'react-router-dom'

import theme from './styles/theme'

import AsyncComponent from './components/AsyncComponent'

const App = AsyncComponent( () => import( './components/app' ) )
const NotFound = AsyncComponent( () => import( './components/not_found' ) )

require( './styles/styles.css' )
require( './styles/espn_font.css' )
require( '../node_modules/video-react/dist/video-react.css' )


function renderApp( routeProps ) {
	return ( <App routeProps={ routeProps } /> )
}


function renderNotFound( routeProps ) {
	return ( <NotFound /> )
}


ReactDom.render(
	<MuiThemeProvider theme={ theme }>
		<BrowserRouter>
			<Switch>
				<Route exact path="/" render={ renderApp } />
				<Route exact path="/timeline" render={ renderApp } />
				{ /*
					Smile project
					<Route exact path="/smile" render={ renderApp } />
				*/ }
				<Route exact path="/compose" render={ renderApp } />
				<Route exact path="/settings" render={ renderApp } />
				<Route exact path="/drafts" render={ renderApp } />
				{ /* <Route exact path="/commentary" render={ renderApp } /> */ }
				{ /* <Route exact path="/events" render={ renderApp } /> */ }
				<Route exact path="/settings/twitter/callback" render={ renderApp } />
				<Route exact path="/edit/:post_id" render={ renderApp } />

				<Route render={ renderNotFound } status={ 404 } />
			</Switch>
		</BrowserRouter>
	</MuiThemeProvider>,
	document.getElementById( 'app' )
)
