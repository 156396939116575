import { createMuiTheme } from '@material-ui/core/styles'

import red from '@material-ui/core/colors/red'
import blueGrey from '@material-ui/core/colors/blueGrey'


const theme = createMuiTheme( {
	palette: {
		primary		: red,
		secondary	: blueGrey,
	},
	typography: {
	}
} )


export default theme
